<template>
  <div>
    <div class="consult" v-show="showImg" @click="changeImg">
      <img src="../assets/img/consult.svg" alt="" />
      <p @click.stop="consultation">保险咨询</p>
    </div>
    <div class="consultMin" v-show="!showImg" @click="changeImg">
      <img src="../assets/img/consult.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { getUser } from "../utils/api";
import { QYconfig } from "../utils/qiyukf";
export default {
  data() {
    return {
      showImg: false,
    };
  },
  created() {},
  methods: {
    //点击展开收起
    changeImg() {
      this.showImg = !this.showImg
    },
    async consultation() {
      this.$toast.loading({
        message: "跳转中...",
        forbidClick: true,
        duration: 0,
      });
      await getUser().then((res) => {
        QYconfig({
          uid: res.data.usersStaff.userId + "zxzx",
          name: res.data.usersStaff.staffName,
          data: JSON.stringify([
            {
              key: "projectName",
              label: "项目名称",
              value: res.data.project.name,
            },
            { key: "source", label: "来源", value: "在线咨询" },
          ]),
        });
      });
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
.consult {
  position: fixed;
  right: 0;
  bottom: 27px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ef7547;
  border-right: 0;
  width: 105px;
  height: 36px;
  border-radius: 100px 0px 0px 100px;
  p {
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    line-height: 12px;
  }
}
.consultMin {
  position: fixed;
  right: 12px;
  bottom: 27px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ef7547;
  border-right: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
</style>