/*
 * @Author: your name
 * @Date: 2020-12-08 15:34:48
 * @LastEditTime: 2020-12-08 16:22:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \app\index.js
 */
(function(w, d, n, a, j) {
  w[n] = w[n] || function() {
      (w[n].a = w[n].a || []).push(arguments);
  };
  j = d.createElement('script');
  j.async = true;
  j.src = 'https://qiyukf.com/script/38318417ea655d768a1911d15ca693d4.js?hidden=true';
  console.log(d.body);
  d.body.appendChild(j);
})(window, document, 'ysf');

// var defalutData = JSON.stringify([
//     { "key": "real_name", "value": "白泽" },
//     { "key": "mobile_phone", "hidden": false },
//     { "key": "email", "value": "testMan@163.com" },
//     { "index": 0, "key": "account", "label": "账号", "value": "snowHonor" },
//     { "index": 1, "key": "sexy", "label": "性别", "value": "先生" },
//     { "index": 5, "key": "reg_date", "label": "注册日期", "value": "2015-11-16" },
//     { "index": 6, "key": "last_login", "label": "上次登录时间", "value": "2015-12-22 15:38:54" },
//     { "index": 8, "key": "medical_extra", "label": "医学解释", "value": "大家都应该重视心脑血管病，积极倡导此病的预防和宣传力度" }
// ])
export function QYconfig(pramars) {
  //promise sdk have been loaded.
  ysf('onready', function() {
      //modify css sheet style by asyncDomatic operation\
      ysf('config', {
          uid: pramars.uid,
          name: pramars.name,
          email: pramars.email,
          mobile: pramars.mobile,
          data: pramars.data
      });
      window.location.href = ysf('url')
  })
}
export function quit() {
  ysf('logoff')
}