<template>
  <div class="home">
    <div class="banner">
      <img src="../assets/img/banner.png" alt="" />
    </div>
    <div class="list">
      <div class="item" @click="supportConcept">
        <div>
          <i></i>
          <p>保障方案</p>
        </div>
        <van-icon size="18" name="arrow" color="#999" />
      </div>
      <div class="item" @click="claimApplication">
        <div>
          <i></i>
          <p>理赔申请</p>
        </div>
        <van-icon size="18" name="arrow" color="#999" />
      </div>
      <div class="item" @click="claimList">
        <div>
          <i></i>
          <p>理赔查询</p>
        </div>
        <van-icon size="18" name="arrow" color="#999" />
      </div>
      <div class="item" @click="user">
        <div>
          <i></i>
          <p>信息完善</p>
        </div>
        <van-icon size="18" name="arrow" color="#999" />
      </div>
    </div>
    <supportConcept />
  </div>
</template>

<script>
import supportConcept from "../components/insuranceConsultation";
export default {
  data() {
    return {};
  },
  components: { supportConcept },
  created() {},
  methods: {
    //保障方案
    supportConcept() {
      this.$router.push({
        path: "/supportConcept",
      });
    },
    //理赔查询
    claimList() {
      this.$router.push({
        path: "/claimList",
      });
    },
    //理赔申请
    claimApplication() {
      this.$router.push({
        path: "/claimApplication",
      });
    },
    //信息完善
    user() {
      this.$router.push({
        path: "/user",
      });
    },
  },
  mounted() {
    let token = this.$route.query.token;
    localStorage.setItem("user-token", token);
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  // padding: 20px;
  .banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 12px;
    padding-top: 20px;
    .item {
      width: 100%;
      height: 75px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
        i {
          width: 4px;
          height: 75px;
          display: block;
          background: #e94709;
          border-radius: 3px 0px 0px 3px;
        }
        p {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
          margin-left: 14px;
        }
      }
      /deep/ .van-icon {
        margin-right: 10px;
      }
    }
    .item:first-child {
      margin-top: 0;
    }
  }
}
</style>